import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { mainTimeout } from '../../services/tempos';

/**
 * @component
 * @returns {React.ReactElement}
 */
export default function ExpoTemp(){

  const navigate = useNavigate();
  mainTimeout(navigate);

  return(
    <main className="expoTemp">
      <Link to="/" className='toHome'/>
      <Link to="/agent" className='agent'></Link>
    </main>
  )
}