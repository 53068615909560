export const duration= 1500;
export const backHome=  10*60*1000
export const steps = {
  typing    : 0,
  valid     : 1,
  unvalid   : 2
}

export const answerQ1    = "3827";
export const answerQ2    = "chine";
export const answerQ3    = "459K3A";
export const answerQ4    = [2,1,5,3,0,4];
export const failTarget  = ".";