import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { steps } from '../../services/settings';

/**
 * @component
 *
 * @returns {React.ReactElement}
 */
export default function PwdIcon({ step }) {
  const icons = {
    0: icon({ name: "circle-check", style: 'regular' }),
    1: icon({ name: "circle-check", style: 'solid' }),
    2: icon({ name: "circle-xmark", style: 'regular' }),
  }
  const classes = Object.keys(steps);
  return (<FontAwesomeIcon icon={icons[step]} className={"result " + classes[step]} />)
}