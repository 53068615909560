
import { backHome, duration } from "./settings";
let tempo;
let mainTempo;

let preventBack=true;

export function mainTimeout(fn ){
  clearTimeout(mainTempo);
  mainTempo = setTimeout(() => { fn("/") }, backHome);
}

export function delayTimeout(fn, next){
  clearDelay();
  tempo = setTimeout(() => fn(next), duration);
}

export function clearDelay(){
  clearTimeout(tempo)
}

export function setPreventBack(prevent){
  preventBack = prevent;
}

export function shouldPrevent(){
  return preventBack;
}

//TODO add clearDelay on Q1, Q2 & Q3