import "./css/style.scss";
import * as serviceWorkerRegistration   from './services/serviceWorkerRegistration';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React                            from "react";
import ReactDOM                         from "react-dom/client";
import About                            from "./pages/About/About";
import Agent                            from "./pages/Agent/Agent";
import Answer                           from "./pages/Answer/Answer";
import DemandeOK                        from "./pages/DemandeOK/DemandeOK";
import Donations                        from "./pages/Donations/Donations";
import Explications                     from "./pages/Explications/Explications";
import ExpoTemp                         from "./pages/ExpoTemp/ExpoTemp";
import Home                             from "./pages/Home/Home";
import Question1                        from "./pages/Question1/Question1";
import Question2                        from "./pages/Question2/Question2";
import Question3                        from "./pages/Question3/Question3";
import Question4                        from "./pages/Question4/Question4";
import Visites                          from "./pages/Visites/Visites";

// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    {
      <BrowserRouter>
        <Routes>
          <Route path="/"                      element={<Home/>} />

          <Route path="a-propos"               element={<About/>} />
          <Route path="agent"                  element={<Agent/>} />
          <Route path="answer"                 element={<Answer/>} />
          <Route path="demandeOK"              element={<DemandeOK/>} />
          <Route path="donations"              element={<Donations/>} />
          <Route path="explications"           element={<Explications/>} />
          <Route path="expositionsTemporaires" element={<ExpoTemp/>} />
          <Route path="question1"              element={<Question1/>} />
          <Route path="question2"              element={<Question2/>} />
          <Route path="question3"              element={<Question3/>} />
          <Route path="question4"              element={<Question4/>} />
          <Route path="visites"                element={<Visites/>} />

          <Route path="*"                      element={<Home/>} />
        </Routes>
      </BrowserRouter>
    }
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
