import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Password } from 'primereact/password';
import PwdIcon from '../../components/PwdIcon/PwdIcon';
import { answerQ3, failTarget, steps } from "../../services/settings";
import { delayTimeout, mainTimeout, setPreventBack } from '../../services/tempos';

let value;

/**
 * @component
 * @returns {React.ReactElement}
 */
export default function Question3() {

  const navigate          = useNavigate();
  const [step, setStep]   = useState(steps.typing);
  mainTimeout(navigate);

  function checkAnswer(value) {
    if (value.length === answerQ3.length) { //bonne réponse
      const next = value.toUpperCase() === answerQ3
        ? steps.valid
        : steps.unvalid;
      delayTimeout(nextStep, next);
      setStep(next);
    }
  }

  function nextStep(nextStep) {
    return nextStep === steps.valid
    ? succeedQuestion()
    : redirectWhenFailed();
  }

  function succeedQuestion(){
    setPreventBack(false);
    navigate("/question4");
  }

  function redirectWhenFailed(){
    failTarget === "."
      ? setStep(steps.typing)
      : navigate(failTarget);
  }

  return (
    <main className="question3">
      <Link to="/" className='agent'></Link>
      <div className='mask'></div>
      {step !== steps.unvalid && <Password value={value} onChange={(e) => checkAnswer(e.target.value)} feedback={false} toggleMask className='inputPwd' autoFocus />}
      <PwdIcon step={step} />
    </main>
  )
}