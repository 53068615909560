import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { mainTimeout } from '../../services/tempos';

/**
 * @component
 * @returns {React.ReactElement}
 */
export default function Explications(){

  const navigate = useNavigate();
  mainTimeout(navigate);

  return(
    <main className="explications">
      <Link to="/agent" className='toHome'/>
      <Link to="/" className='agent'></Link>
      <Link to="/question1" className='start' />
    </main>
  )
}