import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { mainTimeout } from '../../services/tempos';

/**
 * @component
 * @returns {React.ReactElement}
 */
export default function Agent(){

  const navigate = useNavigate();
  mainTimeout(navigate);

  return(
    <main className="agent">
      <Link to="/" />
      <Link to="/demandeOK" />
      <Link to="/demandeOK" />
      <Link to="/explications" />
    </main>
  )
}