import { Link } from 'react-router-dom';
import React from 'react';
import { setPreventBack } from '../../services/tempos';

/**
 * @component
 *
 * @returns {React.ReactElement}
 */
export default function Home(){
  setPreventBack(true);
  return(
    <main className="home">
      <Link to="/expositionsTemporaires"></Link>
      <Link to="/a-propos"></Link>
      <Link to="/visites"></Link>
      <Link to="/donations"></Link>
      <Link to="/agent" className='agent'></Link>
    </main>
  )
}