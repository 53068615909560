import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import PwdIcon from '../../components/PwdIcon/PwdIcon';
import { answerQ4, failTarget, steps } from "../../services/settings";
import { delayTimeout, mainTimeout, setPreventBack } from '../../services/tempos';

/**
 * @component
 * @returns {React.ReactElement}
 */
export default function Question4() {

  const navigate            = useNavigate();
  const [stack, setStack]   = useState([]);
  const [step, setStep]     = useState(steps.typing);
  mainTimeout(navigate);

  function clicked(value) {
    const newStack = stack.length > 0 && stack[stack.length - 1] === value
      ? stack.slice(0, -1)
      : [...stack, value];
    if (newStack.length === 6) {
      setStack([]);
      setStep(checkAnswer(newStack)
        ? goOn()
        : failed()
      );
    }
    else setStack(newStack);
  }

  function checkAnswer(response) {
    for (let i = response.length - 1; i >= 0; i--) {
      if (response[i] !== answerQ4[i]) return false;
    }
    return true;
  }

  function goOn() {
    delayTimeout(nextStep, steps.valid);
    setPreventBack(false);
    return steps.valid;
  }

  /**
   *
   * @param {number} el
   * @returns
   */
  function defineClassName(el) {
    if (step === steps.unvalid) return "";
    return stack.some(current => current === el)
      ? "selected"
      : "";
  }

  function failed() {
    delayTimeout(nextStep, steps.unvalid);
    return steps.unvalid;
  }

  function nextStep(nextStep) {
    return nextStep === steps.valid
      ? navigate("/answer")
      : redirectWhenFailed();
  }

  function redirectWhenFailed() {
    failTarget === "."
      ? setStep(steps.typing)
      : navigate(failTarget);
  }

  return (
    <main className="question4">
      <Link to="/" className='agent'></Link>
      {Array.from(Array(6).keys()).map(el => <button key={"btn" + el} className={defineClassName(el)} onClick={() => clicked(el)}>
        {stack.indexOf(el) === -1 ? "" : stack.indexOf(el) + 1}
      </button>)}
      <PwdIcon step={step} />
    </main>
  )
}
