import { Link, useNavigate } from 'react-router-dom';
import { mainTimeout, shouldPrevent } from '../../services/tempos';
import React, { useEffect } from 'react';

/**
 * @component
 * @returns {React.ReactElement}
 */
export default function Answer(){
  const navigate = useNavigate();
  useEffect(()=>{
    if (shouldPrevent()) navigate("/home");
  },[navigate])
  mainTimeout(navigate);
  return(
    <main className="answer">
      <Link to="/" className='agent'></Link>
    </main>
  )
}